import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import { RxDashboard, RxEyeOpen, RxPencil1, RxPerson, RxPlus } from 'react-icons/rx';
import { useReportedChannelStore,ReportedChannelStore,ReportedChannelStoreContext } from "./store/ReportedChannelStore";
import Filter from "./ReportedChannelFilter/reportedchannelfilter";
import { MdAdd, MdReportGmailerrorred } from "react-icons/md";
import { useState } from "react";
import Pagination from "react-js-pagination";

const ReportedChannelManagement = () => {
    const store = new ReportedChannelStore({
      isloading:true,
      editloading:false,
      pagginationcount:1
    });
    return(
      <ReportedChannelStoreContext.Provider value={store}>
        <Screen/>
      </ReportedChannelStoreContext.Provider>
    )
  }
  
  export default ReportedChannelManagement;

  const Screen = observer(()=>{

    const [reportedid,setreportedid] = useState("")
    const [roomId, setRoomId] = useState("");
    const [roomname, setroomname] = useState("");
    const [trendingstatus, setTrendingstatus] = useState(false);
    const [isHide, setIsHide] = useState(null);

    const {
      users,
      nolist,
      statusChange,
      deleteGroup,
      removeTrendingGroup,
      activePage,
      perPage,
      Count,
      setActivePage,
      setIndexPage,
      nofilterwithpagination,
      NofilterwithPagination,
      filtertrue,
      getFilterUsers,
      filterVisibility,
      getUsers,
      indexPage, 
      loaduser, 
      loadTableData,
      getstatusid,
      loadReported
    }= useReportedChannelStore()

    const handlePageChange = (pageNumber) => {
      NofilterwithPagination(true)
      const element = document.getElementById("tablescroll");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
        if(filtertrue){
          getFilterUsers(pageNumber)
          setIndexPage(pageNumber);
          setActivePage(pageNumber);
        }else{
          if (filterVisibility === true) {
            getUsers(pageNumber);
            setActivePage(pageNumber);
            setIndexPage(pageNumber);
          } else {
            setActivePage(pageNumber);
            setIndexPage(pageNumber);
          }
        }
      };

    return(
      <>
      <div className='flex justify-between'>
      <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">Reported Channel List</p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
        
       </div>
       {/* <Filter/> */}
       <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="w-full">
        <table className="table table-zebra w-full">
            <thead>           
            <tr>
                <th>S.no</th>
                <th>Reported By</th>
                <th>Reported For</th>
                <th>Reported Date</th>
                <th>Reported Reason</th>
                <th>Report Status</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody className="text-sm relative">
            {loaduser && 
              <>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              </>
              }

              {
                users && users.map((res,index) => (
                <tr key={index}>
               <td>  {perPage * (indexPage - 1) + (index + 1)}</td>

                <td className=" capitalize"><span className=" font-bold">{res?.userId?.name }</span> <p className="capitalize text-xs">+{res?.userId?.phone_number}</p></td>
                <td className=" capitalize"><span className=" font-bold">{res?.channelId?.name }</span></td>
                <td className=" capitalize"><Moment format="MM/DD/YYYY">{res.createdAt ?? "N/A"}</Moment></td>
                <td className=" capitalize">
                  {res?.reason?.length >= 25 ?  
                  (res?.reason ? <div className="tooltip"> {(`${res?.reason}`).substring(0, 25).concat('...') ?? "N/A"}
                  <span className="tooltiptext bg-[#000]" > {res?.reason}</span>
                </div>
                :
                "N/A")
                  : 
               <span className="" > {res?.reason}</span>
                  }
                  {/* {res?.report_reason ? <div className="tooltip"> {(`${res?.report_reason}`).substring(0, 20).concat('...') ?? "N/A"}
                    <span className="tooltiptext bg-[#000]" > {res?.report_reason}</span>
                  </div>
                  :
                  "N/A"
                  } */}
                  
                </td>
                
                {/* <th>
                    <label
                      title="Change Status"
                      htmlFor="my-modal-5"
                      className={`btn capitalize ${res?.is_block === "Unblock" ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        setreportedid(res?.id);
                        setreportedstatus(res.is_block)
                      }}
                    >
                     {res.is_block !== "Pending" && "User Is"} {res?.is_block ==="Pending" ? "Pending" : (res?.is_block === "Unblock"? "Activated": "Inactivated" )  }
                    </label>
                  </th> */}
                  {/* <td></td> */}
                {/* <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      <button title="Edit">
                        <Link to={`/ReportedChannelmanagement/${1}`}>
                            <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td> */}
                  <td>
              <div className="flex items-center space-x-4">
                  {res?.channelId?.isHide == true ?
                    <label
                    title="Change Status"
                    htmlFor="my-modal-5"
                    className={`btn capitalize ${ res?.channelId?.isHide == 1 && "bg-warning"} hover:bg-warning  text-[#000] border-hidden  btn-xs`}
                    onClick={(e) => {
                      setRoomId(res.channelId?._id);
                      setIsHide(res.channelId?.isHide);
                      // getNewstatus(res.isActive);
                    }}
                  >
                    {res.channelId?.isHide == true && "Unhide"}
                  </label>
                  :
                  <label
                  title="Change Status"
                  htmlFor="my-modal-5"
                  className={`btn capitalize ${!res.channelId?.isHide ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden mr-4 btn-xs`}
                  onClick={(e) => {
                    setRoomId(res.channelId?._id);
                    setIsHide(res.channelId?.isHide);
                    // getNewstatus(res.isActive);
                  }}
                >
                  {res.channelId?.isHide == false && "Hide"}
                </label>
                  }
                 {/*<button className={`btn capitalize bg-[#ff0000] hover:bg-[#ff0000]  text-[#fff] border-hidden  btn-xs ml-2`}>Delete</button>*/}
                 <label
                  title="Delete Group"
                  htmlFor="delete-modal"
                  className={`btn capitalize bg-[#ff0000] hover:bg-[#ff0000] text-[#fff] border-hidden ml-2  btn-xs`}
                  onClick={(e) => {
                    setRoomId(res.channelId?._id);
                    setroomname(res.channelId?.name)
                    // getNewstatus(res.isActive);
                  }}
                >
                  Delete
                </label>
                
                <label
                title="Remove Trending"
                htmlFor="remove-trending"
                className={`btn capitalize ${res.channelId?.shouldTrending  ? "bg-[#ff0000] hover:bg-[#ff0000] text-[#fff]" : "bg-warning hover:bg-warning text-[#000]" }  border-hidden ml-2  btn-xs`}
                onClick={(e) => {
                  setRoomId(res.channelId?._id);
                  setTrendingstatus(!res.channelId?.shouldTrending);
                  // getNewstatus(res.channelId?.isActive);
                }}
              >
                {res.channelId?.shouldTrending ? "Remove Trending" : "Add Trending"}
              </label>
                
                   </div>
            </td>
                </tr>
             ))
            }

            {nolist && 
              <tr>
                <td colSpan={7} className="my-10 text-center">
                  <MdReportGmailerrorred className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No Reported Channel Found</p>
                </td>
              </tr>
            }
            </tbody>
        </table>
        <Pagination
          activePage={activePage}
          containerClassName={'pagination'}
          itemsCountPerPage={Number(perPage)}
          totalItemsCount={Number(Count)}
          pageRangeDisplayed={10}
          onChange={handlePageChange}
        />
        {/* status change model start */}
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {statusChange(roomId, !isHide)}}
                >
                  Yes
                </label>
                <label htmlFor="my-modal-5" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}

          {/* group delete model start */}
        <input type="checkbox" id="delete-modal" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="delete-modal"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to Delete this channel?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="delete-modal"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {deleteGroup(roomId,roomname)}}
                >
                  Yes
                </label>
                <label htmlFor="delete-modal" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* group delete model end */}

             {/* group delete model start */}
        <input type="checkbox" id="remove-trending" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="remove-trending"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to remove this channel from trending?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="remove-trending"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {removeTrendingGroup(roomId,trendingstatus)}}
                >
                  Yes
                </label>
                <label htmlFor="remove-trending" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
    </div>
       </>
    )
  })